import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import image1 from "../../assets/img/gallery/1.jpg";
import image2 from "../../assets/img/gallery/5.jpg";
import image3 from "../../assets/img/gallery/6.jpg";
import EquipmentData from "../Portfolio/EquipmentData";
import MechanicalTools from "../Portfolio/MechanicalTools";
import InstrumentationTools from "../Portfolio/InstrumentationTools";
import {useNavigate} from 'react-router-dom'

const Details = ({pathName, setPathName}) => {
  
  console.log("pathname is", pathName)
  const navigate = useNavigate()

  const yardImages = [
    "/img/yard-facility/yard-one.jpg",
    "/img/yard-facility/yard-two.jpg",
    "/img/yard-facility/yard-three.jpg",
    "/img/yard-facility/yard-four.jpg",
    "/img/yard-facility/yard-five.jpg",
    "/img/yard-facility/yard-six.jpg",
    "/img/yard-facility/yard-seven.jpg",
    "/img/yard-facility/yard-eight.jpg",
  ]


  return (
    <section className="service-details-wrapper section-padding equipment-table-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-4 mt-5 mt-md-0 col-12 order-2 order-md-1">
            <div className="service-details-sidebar">
              <div className="single-service-sidebar">
                <div className="brochures-download construction-buttons">
                  <a
                    // href="/constructionEquipment"
                    onClick={() => {
                        setPathName("constructionEquipment")
                        navigate('/constructionEquipment')
                    }}
                    className={`theme-btn button-group ${
                      pathName === "constructionEquipment"
                        ? "construction-button"
                        : "construction-selected"
                    }`}
                  >
                    CONSTRUCTION EQUIPMENT & TOOLS
                  </a>
                  <a
                    //   href="/yardFacility"
                    onClick={() => {
                        navigate('/yardFacility')
                        setPathName("yardFacility")}}
                    className={`theme-btn button-group ${
                      pathName === "yardFacility"
                        ? "construction-button"
                        : "construction-selected"
                    }`}
                  >
                    YARD FACILITY
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {pathName == "constructionEquipment" ? (
        <div classname="row">
          <div className="col-lg-12 col-md-4 mt-5">
            <table className="equipment-table">
              <thead>
                <tr>
                  <th class="text-center">Sl.No</th>
                  <th class="text-center">EQUIPMENT/TOOLS</th>
                  <th class="text-center">No's</th>
                </tr>
              </thead>
              <tbody>
                {EquipmentData.map((item) => (
                  <tr>
                    <td class="text-center">{item.slNo}</td>
                    <td>{item.equipmentName}</td>
                    <td class="text-center">{item.number}</td>
                  </tr>
                ))}
                <tr>
                  <th colspan="3" class="text-center">
                    Mechanical Tools & Equipment
                  </th>
                </tr>
                {MechanicalTools.map((item) => (
                  <tr>
                    <td class="text-center">{item.slNo}</td>
                    <td>{item.equipmentName}</td>
                    <td class="text-center">{item.number}</td>
                  </tr>
                ))}
                <tr>
                  <th colspan="3" class="text-center">
                    Instrumentation Tools
                  </th>
                </tr>
                {InstrumentationTools.map((item) => (
                  <tr>
                    <td class="text-center">{item.slNo}</td>
                    <td>{item.equipmentName}</td>
                    <td class="text-center">{item.number}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        ) : (
            <div classname="row">
            <div className="col-lg-12 col-md-4 mt-5 yard-images">
              {yardImages.map((img)=>(
                // <img src="/img/noimage.png" />
                <img src={img} />
              ))}
            </div>
            </div>
        )}
      </div>
    </section>
  );
};

export default Details;
