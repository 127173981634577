import { Link } from "react-router-dom";
import teamTwoData from "./teamTwoData";

const projectData = [
    '/img/projects/p1.jpg',
    '/img/projects/p2.jpg',
    '/img/projects/p3.jpg',
    '/img/projects/p4.jpg',
    '/img/projects/p5.jpg',
    '/img/projects/p6.jpg',
    '/img/projects/p7.jpg',
    '/img/projects/p8.jpg',
    '/img/projects/p9.jpg',
    '/img/projects/p10.jpg',
    '/img/projects/p11.jpg',
    '/img/projects/p12.jpg',
    '/img/projects/p13.jpg',
    '/img/projects/p14.jpg',
    '/img/projects/p15.jpg',
    '/img/projects/p16.jpg',
    '/img/projects/p17.jpg',
    '/img/projects/p18.jpg',
    '/img/projects/p19.jpg',
    '/img/projects/p20.jpg',
    '/img/projects/p21.jpg',
    '/img/projects/p22.jpg',
    '/img/projects/p23.jpg',
    '/img/projects/p24.jpg',
    '/img/projects/p25.jpg',
    '/img/projects/p26.jpg',
    '/img/projects/p27.jpg',
    '/img/projects/p28.jpg',
    '/img/projects/p29.jpg',
    '/img/projects/p30.jpg',
    '/img/projects/p31.jpg',
    '/img/projects/p32.jpg',
    '/img/projects/p33.jpg',
    '/img/projects/p34.jpg',
    '/img/projects/p35.jpg',
    '/img/projects/p36.jpg'
  ];
  
  console.log(projectData);
  

const GalleryTwo = () => {
    return (
        <>
          <section className="our-team-wrapper section-padding">
                <div className="container">
                    <div className="row mtm-40">
                        
                        {
                            projectData.map((data) => (
                                <div className="col-md-6 col-xl-4 col-12" >
                                    <div className="single-team-member">
                                        <div className="team-img">
                                            <img className="project-img" src={data} alt={data.name}/>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
        </>
    )
}

export default GalleryTwo;