import { Link } from "react-router-dom";
import blogOneData from "./BlogOneData";

const licenseData = [
  { title: "Commercial License", url: "/certificates/commercial-license.pdf" },
  { title: "Quality Management System", url: "/certificates/quality-management.pdf" },
  { title: "Health & Safety Management System", url: "/certificates/health-safety.pdf" },
  { title: "Environmental Management System", url: "/certificates/environmental-certificate.pdf" },
  // {title: 'Engineering Professional Practice Certificate'}
];

const BlogOne = () => {
  return (
    <>
      <section
        className="news-wrapper section-padding pt-0"
        style={{ paddingBottom: 0 }}
      >
        <div className="container license-container">
          <div className="row">
            <div className="col-lg-12 col-12 license-div">
              <div className="license-section">
                <div className="license-inside">
                  {licenseData?.map((item) => (
                    <a href={item.url} target="_blank" rel="noopener noreferrer">
                    <div
                      style={{ cursor: "pointer" }}
                      className="license-item"
                    >
                      <img src="/img/license-icon.svg" />
                      <p>{item.title}</p>
                    </div>
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default BlogOne;
