

const InstrumentationTools = [
  { slNo: 39, equipmentName: "RIDGID Stainless Steel Pipe Bending Tool", number: 1 },
  { slNo: 40, equipmentName: "RIDGID Ratchet Flaring Tool", number: 1 },
  { slNo: 41, equipmentName: "Coil Tubing Straightener Tool", number: 1 },
  { slNo: 42, equipmentName: "RIDGID Stainless Steel Pipe Cutting Tool", number: 2 },
  { slNo: 43, equipmentName: "RIDGID Deburring Tool", number: 2 },
  { slNo: 44, equipmentName: "Multi Gas Monitor", number: 6 },
  { slNo: 45, equipmentName: "Multi Gas Monitor (Pump Type)", number: 2 }
];

  

export default InstrumentationTools;