import React, { useState, useEffect } from "react";
import { API_URL } from "../../constants";

const CareerPageContents = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showPhoneNumberError, setShowPhoneNumberError] = useState(false); // State to manage phone number validation
  const SuccessMessage = ({ message, onClose, isSuccess }) => {
    useEffect(() => {
      const timer = setTimeout(() => {
        onClose();
      }, 3000);

      return () => clearTimeout(timer);
    }, [onClose]);

    return (
      <div className={isSuccess ? "success-message" : "error-message"}>
        <span>{message}</span>
        {/* <button onClick={onClose}>X</button> */}
      </div>
    );
  };

  const [formData, setFormData] = useState({
    name: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    jobTitle: "",
    file: null,
  });

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
    // Phone number validation
    if (name === "phoneNumber") {
      if (value.length !== 10 || isNaN(value) || value[0]==='0') {
        setShowPhoneNumberError(true);
      } else {
        setShowPhoneNumberError(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Check if phone number is valid
// if (showPhoneNumberError || formData.phoneNumber.length !== 10) {
//   setShowPhoneNumberError(true);
//   setIsLoading(false);
//   return;
// }
// Proceed with form submission if validation passes
    const apiEndpoint = `${API_URL}api/v1/career/create`;

    const body = new FormData();
    body.append("name", formData.name);
    body.append("lastName", formData.lastName);
    body.append("email", formData.email);
    body.append("phoneNumber", formData.phoneNumber);
    body.append("jobTitle", formData.jobTitle);
    body.append("file", formData.document);

    // Log the FormData contents for debugging
    for (let [key, value] of body.entries()) {
      console.log(`${key}: ${value}`);
    }

    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        body: body,
      });
      console.log(response, "response");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (data.success) {
        setMessage("Submitted successfully");
        setIsSuccess(true);
        setFormData({
          name: "",
          lastName: "",
          email: "",
          phoneNumber: "",
          jobTitle: "",
          file: null,
        })
      }
      setIsLoading(false);
      setShowMessage(true);
      console.log("Success:", data);
    } catch (error) {
      setMessage("Invalid details");
      setIsLoading(false);
      setIsSuccess(false);
      setShowMessage(true);
      console.error("Error:", error);
    }
  };

  return (
    <>
      {showMessage && (
        <SuccessMessage
          message={message}
          onClose={() => setShowMessage(false)}
          isSuccess={isSuccess}
        />
      )}
      <div className="contact-us-wrapper section-padding">
        <div className="container">
          <div className="row eq-height">
            <div className="col-lg-8 col-12">
              <div className="contact-form">
                <h6 className="text-center" style={{ marginBottom: "40px" }}>
                  Looking for a carrier that will take you to another level. If
                  you are the right fit we will absorb you
                </h6>

                <form
                  onSubmit={handleSubmit}
                  method="POST"
                  className="row"
                  id="contact-form"
                >
                  <div className="col-md-6 col-12">
                    <div className="single-personal-info">
                      <input
                        type="text"
                        name="name"
                        value={formData.name}
                        placeholder="First Name"
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="single-personal-info">
                      <input
                        type="text"
                        name="lastName"
                        value={formData.lastName}
                        placeholder="Last Name"
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="single-personal-info">
                      <input
                        type="email"
                        name="email"
                        value={formData.email}
                        placeholder="Email"
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="single-personal-info">
                      <input
                        type="tel"
                        name="phoneNumber"
                        value={formData.phoneNumber}
                        placeholder="Phone"
                        onChange={handleChange}
                        required
                      />
                      {/* {showPhoneNumberError && <p className="quotePageRadioOption">Please enter a valid 10 digits number</p>} */}
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="single-personal-info">
                      <input
                        type="text"
                        name="jobTitle"
                        value={formData.jobTitle}
                        placeholder="Job Title"
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="single-personal-info">
                      <input
                        type="file"
                        name="document"
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12 col-12">
                    {/* <input
                      className="submit-btn"
                      type="submit"
                      value={
                        isLoading ? (
                          <img
                            src="/img/loading-gif.gif"
                            alt="Loading..."
                            className="loading-gif"
                          />
                        ) : (
                          "Submit"
                        )
                      }
                    /> */}
                    <button
                      className="submit-btn"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <img
                          src="/img/loading-gif.gif"
                          alt="Loading..."
                          className="loading-gif"
                        />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>
                <span className="form-message" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CareerPageContents;
