import {v4 as uuidv4} from 'uuid';

const workProcessData = [
    {
        id: uuidv4(),
        icon: 'writing',
        subTitle: 'Mission',
        title: 'QUALITY SOLUTIONS',
        desc: 'Delivering economical, quality-oriented construction solutions for customer satisfaction and sustainability.',
    },

    {
        id: uuidv4(),
        icon: 'flow-chart',
        subTitle: 'Objective',
        title: 'MARKET LEADERSHIP',
        desc: 'Meeting the demands of the UAE\'s fast-growing market to become a premier contracting company',
    },    

    {
        id: uuidv4(),
        icon: 'interior-design',
        subTitle: 'Vision',
        title: 'GLOBAL RECOGNITION',
        desc: 'Striving for world-class status in engineering and construction, setting the standard for excellence globally.',
    },    
]

export default workProcessData;