import React from 'react'
import { Link } from 'react-router-dom'

const MobileMenu = ({ mobileMenu, handleMobileMenu }) => {
    console.log("mobile menu ", mobileMenu)
    return (
        <>
            <div>
                <div className={mobileMenu ? 'mobile-nav' : 'mobile-nav show'}>
                    <button onClick={handleMobileMenu} type="button" className="close-nav">
                        <i className="fal fa-times-circle"></i>
                    </button>
                    <nav className="sidebar-nav">
                        <ul className="metismenu" id="mobile-menu">
                            <li><a className="has-arrow" href="#">PAGES</a>
                                <ul className="sub-menu">
                                    <li><Link to="/">HOME</Link></li>
                                    <li><Link to="/about">ABOUT</Link></li>
                                    <li><Link to="/projects">PROJECTS</Link></li>
                                    <li><Link to="/constructionEquipment">OUR TOOLS</Link></li>
                                    <li><Link to="/careers">CAREERS</Link></li>
                                    <li><Link to="/contact">CONTACT</Link></li>
                                </ul>
                            </li>
                            {/* <li><Link to="/about">about us</Link></li>
                    <li><Link to="/services">Services</Link></li>
                    <li>
                        <a className="has-arrow" href="#">Pages</a>
                        <ul className="sub-menu">
                            <li><Link to="/faq">faq</Link></li>
                            <li><Link to="/pricing">Pricing</Link></li>
                            <li><Link to="/team">Team</Link></li>
                        </ul>
                    </li> */}
                            {/* <li><Link to="/news">News</Link></li>
                    <li><Link to="/contact">Contact</Link></li> */}
                        </ul>
                    </nav>


                    <div className="action-bar offset-bar">
                        <a className="has-arrow" href="#">CONTACT INFO</a>
                        <a href=""><i className="fal fa-map"></i>DH Tech Building Contracting LLC
                            <p>Business Center,
                                Rubaya Building,Al Qusais
                                Dubai, United Arab Emirates</p></a>
                        <a href="mailto:info@dhtech.ae"><i className="fal fa-envelope"></i>info@dhtech.ae</a>
                        <a href="tel:+971 4 380 9355"><i className="fal fa-phone"></i>+971502033924</a>
                        <a href="tel:+971 4 380 9355"><i className="fal fa-clock"></i>Week Days: 8:00 AM - 6:00 PM</a>
                        <Link to='/contact' className="d-btn theme-btn"> Consultation</Link>
                    </div>
                </div>
            </div>
            {!mobileMenu && <div className="mobile-menu-backdrop" onClick={handleMobileMenu} ></div>}
        </>

    )
}

export default MobileMenu