import { Link } from "react-router-dom";
import FooterOneCopyrights from "./FooterOneCopyrights";
import { useState } from "react";
import FooterCta from "./FooterCta";

const FooterOne = () => {
  const [input, setInput] = useState("");
  return (
    <>
      <footer className="footer-1 footer-wrap">
        <div className="footer-widgets-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 pe-xl-0 col-sm-6 col-12">
                <div className="single-footer-wid site_info_widget">
                  <div className="wid-title">
                    <h3>Get In Touch</h3>
                  </div>
                  <div className="contact-us">
                    <div className="single-contact-info">
                      <div className="icon">
                        <i className="fal fa-phone"></i>
                      </div>
                      <div className="contact-info">
                        <a
                          className="footer-contact"
                          href="tel:+971502033924"
                        >
                          <p>Office : +971502033924</p>
                        </a>
                        <a
                          className="footer-contact"
                          href="tel:+971 4 380 9356"
                        >
                          <p>Fax : +971 4 380 9356</p>
                        </a>
                      </div>
                    </div>
                    <div className="single-contact-info">
                      <div className="icon">
                        <i className="fal fa-envelope"></i>
                      </div>
                      <div className="contact-info">
                        <a href="mailto:info@dhtech.ae">
                          <p className="footer-mail">info@dhtech.ae</p>
                        </a>
                        <a href="marketing@dhtech.ae">
                        <p className="footer-mail">marketing@dhtech.ae</p>
                        </a>
                      </div>
                    </div>
                    <div className="single-contact-info">
                      <div className="icon">
                        <i className="fal fa-map-marker-alt"></i>
                      </div>
                      <div className="contact-info footer-address">
                        <p>DH Tech</p>
                        <p>Business Center,</p>
                        <p>Rubaya Building,Al Qusais</p>
                        <p>Dubai, United Arab Emirates</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-sm-6 offset-xl-1 col-xl-3 ps-xl-5 col-12">
                <div className="single-footer-wid">
                  <div className="wid-title">
                    <h3>Quick Links</h3>
                  </div>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/projects">Our Projects</Link>
                    </li>
                    <li>
                      <Link to="/careers">Career</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/qualityPolicy">Quality Policy</Link>
                    </li>
                    <li>
                      <Link to="/healthSafety">
                        Health Safety And Environment Policy
                      </Link>
                    </li>
                    {/* <li>
                      <Link to="/contact">Payment Getway</Link>
                    </li> */}
                  </ul>
                </div>
              </div>

              <div className="col-sm-6 col-xl-4 offset-xl-1 col-12">
                <div className="single-footer-wid newsletter_widget">
                  <div className="wid-title">
                    <h3>Our Location</h3>
                  </div>
                  <div className="map-location">
                    {/* <img src={footerMap} alt="" /> */}
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d57719.390073359624!2d55.3398554!3d25.2886815!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f5c35f895f055%3A0x6d3c5f9fe1e0fe3d!2sAl%20Rubaya%20Building!5e0!3m2!1sen!2sin!4v1717650400465!5m2!1sen!2sin"
                      width="100%"
                      height="275"
                      style={{ borderRadius: "16px", border: "none" }}
                      // style={
                      //   isBigScreen
                      //     ? { borderRadius: "16px", border: "none" }
                      //     : { borderRadius: "8px", border: "none" }
                      // }
                      allowFullScreen
                      loading="lazy"
                      referrerPolicy="no-referrer-when-downgrade"
                      title="Contact Us"
                    ></iframe>

                  </div>
                  <p>
                    Opening Hours : <b> Mon - Fri: 8:00 AM - 6:00 PM</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <FooterCta />
        <FooterOneCopyrights />
      </footer>
    </>
  );
};

export default FooterOne;
