import React, { useState, useEffect } from 'react';
import { API_URL } from '../../constants';

const ContactPageContents = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showPhoneNumberError, setShowPhoneNumberError] = useState(false); // State to manage phone number validation
  const SuccessMessage = ({ message, onClose, isSuccess }) => {
    useEffect(() => {
      const timer = setTimeout(() => {
        onClose();
      }, 3000);

      return () => clearTimeout(timer);
    }, [onClose]);

    return (
      <div className={isSuccess ? 'success-message' : 'error-message'}>
        <span>{message}</span>
        {/* <button onClick={onClose}>X</button> */}
      </div>
    );
  };

  const [contactData, setContactData] = useState({
    name: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    message: '',
  });
  const handleContactChange = (e) => {
    const { name, value } = e.target;
    setContactData({
      ...contactData,
      [name]: value,
    });
    // Phone number validation
    if (name === "phoneNumber") {
      if (value.length !== 10 || isNaN(value) || value[0]==='0') {
        setShowPhoneNumberError(true);
      } else {
        setShowPhoneNumberError(false);
      }
    }
  };
  const handleContactSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    // Check if phone number is valid
// if (showPhoneNumberError || contactData.phoneNumber.length !== 10) {
//   setShowPhoneNumberError(true);
//   setIsLoading(false);
//   return;
// }
// Proceed with form submission if validation passes
    const apiEndpoint = `${API_URL}api/v1/contact/create`;

    const body = {
      name: contactData.name,
      lastName: contactData.lastName,
      email: contactData.email,
      phoneNumber: contactData.phoneNumber,
      message: contactData.message,
    };

    try {
      const response = await fetch(apiEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();
      if (data.success) {
        setMessage('Contact request submitted successfully');
        setIsSuccess(true);
        setContactData({
          name: '',
          lastName: '',
          email: '',
          phoneNumber: '',
          message: '',
        })
      }
      setIsLoading(false);
      setShowMessage(true);
      console.log('Success:', data);
    } catch (error) {
      setMessage('Invalid contact details');
      setIsLoading(false);
      setIsSuccess(false);
      setShowMessage(true);
      console.error('Error:', error);
    }
  };

  return (
    <>
    
    {showMessage && <SuccessMessage message={message} onClose={() => setShowMessage(false)} isSuccess={isSuccess} />}
    <div className="contact-us-wrapper section-padding">
      <div className="container">
        <div className="row eq-height">
          <div className="col-lg-8 col-12">
            <div className="contact-form contact-form-left">
              <h2>Get in Touch</h2>

              <form
                // action="mail.php"
                onSubmit={handleContactSubmit}
                method="POST"
                className="row"
                id="contact-form"
              >
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input type="text" name="name" value={contactData.name} placeholder="First Name" onChange={handleContactChange} required />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input type="text" name="lastName" value={contactData.lastName} placeholder="Last Name" onChange={handleContactChange} required />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input type="email" name="email" value={contactData.email} placeholder="Email" onChange={handleContactChange} required />
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="single-personal-info">
                    <input type="text" name="phoneNumber" value={contactData.phoneNumber} placeholder="Number" onChange={handleContactChange} required />
                    {/* {showPhoneNumberError && <p className="quotePageRadioOption">Please enter a valid 10 digits number</p>} */}
                  </div>
                </div>
                <div className="col-md-12 col-12">
                  <div className="single-personal-info">
                    <textarea
                      name="message" value={contactData.message}
                      placeholder="message" onChange={handleContactChange}
                      required   defaultValue={""}
                    />
                  </div>
                </div>
                <div className="col-md-12 col-12">
                  {/* <input
                    className="submit-btn"
                    type="submit"
                    disabled={isLoading}
                    defaultValue="Submit Now"
                  /> */}
                  <button
                      className="submit-btn"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <img
                          src="/img/loading-gif.gif"
                          alt="Loading..."
                          className="loading-gif"
                        />
                      ) : (
                        "Submit"
                      )}
                    </button>
                </div>
              </form>
              <span className="form-message" />
            </div>
          </div>
          <div className="col-lg-4 col-12">
            <div className="contact-us-sidebar mt-5 mt-lg-0">
              <div className="contact-info">
                <h2>CONTACT INFO</h2>
                <a href="mailto:INFO@dhtech.ae">
                  <div className="single-info">
                    <div className="icon">
                      <i className="flaticon-email" />
                    </div>
                    <div className="text">
                      <span className="span-contact">Email Us</span>
                      <h6 style={{ marginBottom: 0 }}>info@dhtech.ae</h6>
                    </div>
                  </div>
                </a>
                <a href="tel:+971502033924">
                <div className="single-info">
                  <div className="icon">
                    <i className="flaticon-phone-call-1" />
                  </div>
                  <div className="text">
                    <span className="span-contact">Call Us</span>
                    <h6 style={{ marginBottom: 0 }}>+971502033924</h6>
                  </div>
                </div>
                </a>
                <a href="tel:+971 4 380 9356">
                <div className="single-info">
                  <div className="icon">
                    <i className="flaticon-phone-call-1" />
                  </div>
                  <div className="text">
                    <span className="span-contact">Fax</span>
                    <h6 style={{ marginBottom: 0 }}>+971 4 380 9356</h6>
                  </div>
                </div>
                </a>
                <div className="single-info">
                  <div className="icon">
                    <i className="flaticon-phone-call-1" />
                  </div>
                  <div className="text">
                    <span>Hours</span>
                    <h6 style={{ marginBottom: 0 }}>08.00am - 6.00pm</h6>
                  </div>
                </div>
                <div className="single-info">
                  <div className="icon">
                    <i className="flaticon-pin" />
                  </div>
                  <div className="text">
                    <span>Location</span>
                    <h6 style={{ marginBottom: 0 }}>Business Center,</h6>
                    <h6 style={{ marginBottom: 0 }}>Rubaya Building,Al Qusais</h6>
                    <h6 style={{ marginBottom: 0 }}>Dubai, United Arab Emirates</h6>
                  </div>
                </div>
              </div>
              {/* <div className="live-chat">
                            <h2>Live Chat</h2>
                            <p>You can contact us on chat and explain 
                            your problems</p>
                            <Link to="/about"><i className="flaticon-chatting" /> Live Chat to Executive</Link>
                        </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
    </>
  );
};

export default ContactPageContents;
