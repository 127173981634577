import React from 'react'
import FooterTwo from '../Components/Footer/FooterTwo'
import HeaderOne from '../Components/Header/HeaderOne'
import bannerBg from '../assets/img/page-banner.jpg';
import PageBanner from '../Components/PageBanner';
import BrandCarouselThree from "../Components/Brands/BrandCarouselThree";
import ContactPageContents from "../Components/Contact/ContactPageContents";
import QuotePageContents from '../Components/Free Quote/QuotePageContents';

const QuotePage = () => {
  return (
    <>
        <HeaderOne/>
        <PageBanner title='Request  A  Quote' bannerBg={bannerBg} currentPage='Request a Quote' />
        <QuotePageContents/>
        <BrandCarouselThree/>
        <FooterTwo/>
    </>
  )
}

export default QuotePage;
