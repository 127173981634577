import {v4 as uuidv4} from 'uuid';
import portfolio1 from '../../assets/img/project/1.jpg';
import portfolio2 from '../../assets/img/project/2.jpg';
import portfolio3 from '../../assets/img/project/3.jpg';
import portfolio4 from '../../assets/img/project/4.jpg';

const EquipmentData = [
    { slNo: 1, equipmentName: "Bus - 35-Seater", number: 1 },
    { slNo: 2, equipmentName: "Mini-Bus - 25-Seater", number: 2 },
    { slNo: 3, equipmentName: "Double Cabin Pickup", number: 8 },
    { slNo: 4, equipmentName: "Water tanker 5000GL.", number: 1 },
    { slNo: 5, equipmentName: "JCB", number: 1 },
    { slNo: 6, equipmentName: "Skid Steer Loader- Track mounted", number: 5 },
    { slNo: 7, equipmentName: "Plate Compactors", number: 4 },
    { slNo: 8, equipmentName: "Roller Compactors - 1 Ton", number: 4 },
    { slNo: 9, equipmentName: "Concrete Vibrators", number: 6 },
    { slNo: 10, equipmentName: "Auto-Leveling Instrument", number: 4 },
    { slNo: 11, equipmentName: "Total Station", number: 1 },
    { slNo: 12, equipmentName: "Angle Grinder/Cutter", number: 20 },
    { slNo: 13, equipmentName: "Hammer Drills", number: 9 },
    { slNo: 14, equipmentName: "Aluminum Scaffoldings", number: 4 },
    { slNo: 15, equipmentName: "Disc Cutter 12 inch", number: 4 },
    { slNo: 16, equipmentName: "Circular Saw 12 inch", number: 4 },
    { slNo: 17, equipmentName: "Makita Cordless Drill Driver", number: 6 },
    { slNo: 18, equipmentName: "Rotary Hammer 24 mm", number: 2 },
    { slNo: 19, equipmentName: "Radio Detection RD7100 Cable Scanner", number: 2 },
    { slNo: 20, equipmentName: "Portable Generators", number: 3 },
    { slNo: 21, equipmentName: "Concrete Breaker (15 Kg)", number: 4 },
    { slNo: 22, equipmentName: "Portable Office Cabins 20 ft", number: 3 },
    { slNo: 23, equipmentName: "Portable Store Container 20 ft", number: 4 },
    { slNo: 24, equipmentName: "Portable Toilet Block 20ft & 6ft", number: 2 },
    { slNo: 25, equipmentName: "Pallet Trolley - 3 ton", number: 3 },
    { slNo: 26, equipmentName: "Welding Machine", number: 12 },
    { slNo: 27, equipmentName: "Pressure Washer", number: 2 },
    { slNo: 28, equipmentName: "Hydraulic Crimping Tool", number: 1 }
  ];
  
  

export default EquipmentData;