import { Link } from "react-router-dom";
import mainLogo from "../../assets/img/logo.png";

const OffsetMenu = ({ offset, handleOffset }) => {
  return (
    <>
      <div>
        <div className={offset ? "offset-menu" : "offset-menu show"}>
          <span onClick={handleOffset} id="offset-menu-close-btn">
            <i className="fal fa-plus"></i>
          </span>
          <div className="offset-menu-wrapper text-white">
            <div className="offset-menu-header">
              <div className="offset-menu-logo">
                <Link to="/#">
                  <img src="/img/dhtech-icon.svg" alt="logo" />
                </Link>
              </div>
            </div>
            <div className="offset-menu-section">
              <h3>About Us</h3>
              <p>
                Our strength is our well experienced team with proven expertise in
                the respective fields.
              </p>
              <Link to="/contact" className="theme-btn  mt-30">
                Consultation
              </Link>
            </div>
            <div className="offset-menu-section">
              <h3>Contact Info</h3>
              <ul>
                <li>
                  <span>
                    <i className="fal fa-map"></i>
                  </span>
                  DH Tech Building Contracting LLC
                  <p>Business Center,
                    Rubaya Building,Al Qusais
                    Dubai, United Arab Emirates</p>
                </li>
                <a className="footer-contact" href="tel:+971502033924">
                  <li>
                    <span>
                      <i className="fal fa-phone"></i>
                    </span>
                    +971502033924
                  </li>
                </a>
                <a className="footer-contact" href="mailto:info@dhtech.ae">
                  <li>
                    <span>
                      <i className="fal fa-envelope"></i>
                    </span>
                    info@dhtech.ae
                  </li>
                </a>
                <li>
                  <span>
                    <i className="fal fa-clock"></i>
                  </span>
                  Week Days: 8:00 AM - 6:00 PM
                </li>
                <li>
                  <span>
                    <i className="fal fa-clock"></i>
                  </span>
                  Sunday: Closed
                </li>
              </ul>
            </div>
            <div className="offset-menu-footer">
              <div className="offset-menu-social-icon">
                <a href="#">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="#">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#">
                  <i className="fab fa-linkedin-in"></i>
                </a>
                <a href="#">
                  <i className="fab fa-dribbble"></i>
                </a>
                <a href="#">
                  <i className="fab fa-youtube"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
        {!offset && <div className="offset-menu-backdrop" onClick={handleOffset} ></div>}
      </div>
    </>
  );
};

export default OffsetMenu;
