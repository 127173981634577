import React from 'react'
import FooterTwo from '../Components/Footer/FooterTwo';
import HeaderOne from '../Components/Header/HeaderOne';
import PageBanner from '../Components/PageBanner/index';
import bannerBg from '../assets/img/page-banner.jpg';
import Details from '../Components/quality-policy/Details';

const QualityPolicy = () => {
  return (
    <>
        <HeaderOne/>
        <PageBanner title='Quality Policy' bannerBg={bannerBg} currentPage='Quality Policy' />
        <Details />
        <FooterTwo/>
    </>
  )
}

export default QualityPolicy