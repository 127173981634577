import videoBg from "../../assets/img/video_bg_1.jpg";
import imgBlock1 from "../../assets/img/home1/img-block1.jpg";
import tabImg1 from "../../assets/img/home1/tab-img.jpg";
import { Link } from "react-router-dom";
import { useRef, useState } from "react";
import "react-modal-video/scss/modal-video.scss";
import ModalVideo from "react-modal-video";

const AboutOne = ({ pt }) => {
  const [isOpen, setOpen] = useState(false);
  const [openAll, setOpenAll] = useState(false);

  return (
    <>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId="EZ9DrY43wtw"
        onClose={() => setOpen(false)}
      />
      <section
        className={
          pt
            ? "about-section section-padding "
            : "about-section section-padding about-page-section pt-0"
        }
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-5 col-12">
              <div className="img-block-pop-video">
                <img src="/img/tall-building.svg" alt="busico" />
                <div
                  className="popup-video-block d-flex justify-content-center align-items-center bg-cover"
                  style={{ backgroundImage: `url('/img/small-building.svg')` }}
                >
                </div>
              </div>
            </div>
            <div className="col-xl-6 col-lg-7 col-md-10 col-12">
              <div className="block-contents">
                <div className="section-title">
                  <span>01. About Company</span>
                  <h2>CIVIL CONTRACTING COMPANY</h2>
                </div>
                <p>
                  DIAMOND HOUSE TECHNICAL SERVICES LLC. is an ISO certified (ISO
                  9001:2015 ISO 18001:2015 & ISO 45001-2018) Civil Contruction
                  and Maintenance Company established in 2007 in Dubai, United
                  Arab Emirates. The company is having an active participation
                  in the fast-emerging growth phases of U.A.E since its
                  inception. The company employs skilled & competent staff who
                  cater to our various projects. The company is registered with
                  Dubai Municipality, Dubai Civil Defense, TRAKHEES, JAFZA,
                  DAFZA, DWC,etc.
                </p>
              </div>
              <div className="tab-content-block">
                <ul
                  className="nav nav-pills mb-3"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-technology-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-technology"
                      type="button"
                      role="tab"
                      aria-controls="pills-technology"
                      aria-selected="true"
                    >
                      Specialities
                    </button>
                  </li>
                  {/* <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-materials-tab" data-bs-toggle="pill" data-bs-target="#pills-materials" type="button" role="tab" aria-controls="pills-materials" aria-selected="false">Materials</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="pills-approved-tab" data-bs-toggle="pill" data-bs-target="#pills-approved" type="button" role="tab" aria-controls="pills-approved" aria-selected="false">Approved</button>
                                </li> */}
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-technology"
                    role="tabpanel"
                    aria-labelledby="pills-technology-tab"
                  >
                    <div className="tab-inner-contents specialities-content">
                      <div className="img-box">
                        <img src="/img/civil-contracting.svg" alt="" />
                      </div>
                      <div className={`checked-features-list specialities-list ${openAll ? 'show' : ''}`}>
                        <ul>
                          <li>
                          Civil and MEP Maintenance
                          </li>
                          <li>
                          Contracting of Residential & Industrial buildings
                          </li>
                          <li>AMC Works</li>
                          <li>Construction of Warehouses</li>
                          <li>Construction of Electrical Substations</li>
                          <li>Industrial Civil construction works</li>
                          <li>Electrical, Instrumentation and Automation works</li>
                          <li>Plumbing & Sanitary Installation</li>
                          <li>Floor & Wall Tiling Works</li>
                          <li>Air-Conditioning, Ventilations & Air Filtration Systems,Installation & Maintenance</li>
                          <li>Building Cleaning Services</li>
                          <li>Painting Contracting</li>
                          <p onClick={()=>setOpenAll(!openAll)} className = "specialities-more">{openAll ? 'Minimize' : 'More'}</p>
                        </ul>
                        
                      </div>

                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-materials"
                    role="tabpanel"
                    aria-labelledby="pills-materials-tab"
                  >
                    <div className="tab-inner-contents">
                      <div className="img-box">
                        <img src={tabImg1} alt="" />
                      </div>
                      <div className="checked-features-list">
                        <ul>
                          <li>Stylistic formula method</li>
                          <li>Intentional development authority</li>
                          <li>Royal ability to listen design</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-approved"
                    role="tabpanel"
                    aria-labelledby="pills-approved-tab"
                  >
                    <div className="tab-inner-contents">
                      <div className="img-box">
                        <img src="assets/img/home1/tab-img.jpg" alt="" />
                      </div>
                      <div className="checked-features-list">
                        <ul>
                          <li>Intentional development authority</li>
                          <li>Stylistic formula method</li>
                          <li>Royal ability to listen design</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {window.location.pathname!=="/about" && (
              <Link to="/about" className="plus-text-btn mt-lg-5 mt-4 d-flex justify-content-start align-items-center">
                <div className="icon">
                  <i className="fas fa-plus"></i>
                </div>
                <div className="link-text">
                  <span>Explore</span> More about Us
                </div>
              </Link>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutOne;
