import React from "react";

const Details = () => {
  return (
    <section className="blog-wrapper news-wrapper section-padding health-safety-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="blog-post-details border-wrap">
              <div className="single-blog-post post-details">
                <div className="post-content">
                  {/* <div className="post-cat">
                                    <Link to="#">BUILDING</Link>
                                </div> */}
                  <h2>HEALTH SAFETY AND ENVIRONMENT POLICY</h2>
                  {/* <div className="post-meta">
                                    <span><i className="fal fa-comments"></i>35 Comments</span>
                                    <span><i className="fal fa-calendar-alt"></i>24th March 2019</span>
                                </div> */}

                  <p>
                    <strong>DIAMOND HOUSE TECHNICAL SERVICES LLC</strong>{" "}
                    believes in an unwavering commitment to protecting the
                    environment, health, and safety of all people involved in
                    our activities and thus achieving an incident-free workplace
                    with strict adherence to environmental protection by
                    preventing all types of pollution.
                  </p>
                  <p>
                    We are committed to continual improvement and regularly
                    review our HSE policies, objectives, and targets to ensure
                    effectiveness and full compliance with our moral, legal, and
                    contractual obligations.
                  </p>
                  <p>HSE policy objectives</p>

                  <ul>
                    <li>
                      A goal of zero harm to people, assets, and the environment
                      shall be continually pursued.
                    </li>
                    <li>
                      Responsibility and commitment to ensure all employees are
                      working in a safe and healthy working environment.
                    </li>
                    <li>
                      A firm belief that all accidents/incidents are preventable
                      and will take every measure to prevent job-related
                      injuries, illness and environmental pollution.
                    </li>
                    <li>
                      Ensure provision for information, instruction, and
                      training that is relevant to employee’s duties to enable
                      them to perform responsibly.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Details;
