import {v4 as uuidv4} from 'uuid';
import portfolio1 from '../../assets/img/project/p1.jpg';
import portfolio2 from '../../assets/img/project/p2.jpg';
import portfolio3 from '../../assets/img/project/p3.jpg';
import portfolio4 from '../../assets/img/project/p1.jpg';

const portfolioTwoData = [ 
    {
        id: uuidv4(),
        img: `/img/noimage.png`,
       // category: 'CONSTRUCTION OF TISSUE FACTORY SHED EXTENSION',
        description:"Civil works for flare and 20E-401 cooling Tower-01 concrete wall demolition and  reinstate the concrete wall",
        contract_amount:'30,000',
        year: '2010 & 2009',
        client: 'EMDAD',
        index: '01',
    },
    {
        id: uuidv4(),
        img: '/img/noimage.png',
      //  category: 'CONSTRUCTION OF SMELTER DE-LINING BUNKER FOR DUBAI ALUMINUM CO',
      description:'Supply, Fabrication & Installation of Class 300 vent pipe' ,
      contract_amount:'20,000 ',
         year: '1999',
        client: 'Agnice Cotracting LLC',
        index: '02',
    },

    {
        id: uuidv4(),
        img: '/img/noimage.png',
     //   category: 'NEW WAREHOUSE FOR ERHARDT AND PARTNER AT DUBAI WORLD CENTRAL',
     description:'Civil works for AC replacement works for substation 3 at VHFL' ,
     contract_amount:'54000',
        year: '1992',
        client: 'Al Amwaj Refrigeration Equipment', 
        index: '03',
    },
    {
        id: uuidv4(),
        img: `/img/noimage.png`,
      //  category: 'INTERIOR WORKS OF OFFICE AND COVERED CAR PARKS AT JAFZA SOUTH',
      description:'Civil Works at VHFL',
      contract_amount:'57,404',
        year: '1933',
        client: 'Altrad',
        index: '04',
    },
    {
        id: uuidv4(),
        img: `/img/noimage.png`,
     //   category: 'RENOVATION OF LABOUR CAMP',
     description:'Padel Court works at Aviation Club' ,
     contract_amount:'34,000',
        year: '1921',
        client: 'Raymond Sports',
        index: '05',
    },

    {
        id: uuidv4(),
        img: `/img/noimage.png`,
     //   category: 'FIRE PUMP ROOM MODIFICATION WORK',
     description:'Outside Plastering works.Painting Works,Plumbing Works,Tile Works,Water Proofing Works,Interlock laying and Kerb stone laying works',
        contract_amount:'76,200',
        year: '1919',
        client: 'Amistad Oil & Natural Gas Pipelines Contracting LLC',
        index: '06',
    },

    {
        id: uuidv4(),
        img: `/img/noimage.png`,
      //  category: 'CONSTRUCTION OF TWO SUBSTATIONS BUILDINGS AND CONTROL ROOM AT PORT OF FUJAIRAH',
      description:'Design and approval for construction of admin building' ,
      contract_amount:'35000',
        year: '1914',
         client: 'Magnum Technology center',
        index: '07',
    },
    {
        id: uuidv4(),
        img: `/img/noimage.png`,
     //   category: 'CONSTRUCTION OF CIVIL FOUNDATION at DUSUP PLANT, MARGHAM',
     description:'Fencing work, construction of beam for rolling gate, Tank containment above interlock' ,
     contract_amount:'45000',
        year: '1895',
         client: 'Oilwell Middle East',
        index: '08',
    },
    // {
    //     id: uuidv4(),
    //     img: `https://www.poweron.ae/assets/uploads/project/refurbishment-of-drainage-and-sanitary-system.jpg`,
    //     category: 'REFURBISHMENT OF DRAINAGE AND SANITARY SYSTEM',
    //     location: 'Dubai',
    //     client: 'M/S Emirates petroleum ltd (EMARAT), Dubai',
    //     index: '09',
    // },
    // {
    //     id: uuidv4(),
    //     img: `https://www.poweron.ae/assets/uploads/project/falcon-d-punch-list-j-afza.jpg`,
    //     category: 'FALCON D PUNCH LIST ( J AFZA)',
    //     location: 'Dubai',
    //     client: 'Enoc/Horizon Terminal Ltd',
    //     index: '10',
    // },
    // {
    //     id: uuidv4(),
    //     img: `https://www.poweron.ae/assets/uploads/project/dubal-corner-risk-improvement-project-for-dusup.jpg`,
    //     category: 'DUBAL CORNER RISK IMPROVEMENT PROJECT FOR DUSUP',
    //     location: 'Dubai',
    //     // client: 'Gerding Development',
    //     index: '11',
    // },
    // {
    //     id: uuidv4(),
    //     img: `https://www.poweron.ae/assets/uploads/project/ia-compressor-system-improvement.jpg`,
    //     category: 'IA COMPRESSOR SYSTEM IMPROVEMENT',
    //     location: 'Dubai',
    //     client: 'M/S Dubai Supply Authority',
    //     index: '12',
    // },
    // {
    //     id: uuidv4(),
    //     img: `https://www.poweron.ae/assets/uploads/project/fuel-tank-replacement-and-allied-works-at-battuta-mall-filling-station-jebel-ali-and-al-shamil-fill.jpg`,
    //     category: 'FUEL TANK REPLACEMENT AND ALLIED WORKS AT BATTUTA MALL FILLING STATION JEBEL ALI AND AL SHAMIL FILLING STATION AT AJMAN',
    //     location: 'Dubai',
    //     client: 'M/S Emirates petroleum(EMARAT)',
    //     index: '13',
    // },
    // {
    //     id: uuidv4(),
    //     img: `https://www.poweron.ae/assets/uploads/project/construction-of-rcc-valve-pits-for-gas-pipe-lines-for-dusup-at-dewa-power-plant-jebel-ali.jpg`,
    //     category: 'CONSTRUCTION OF RCC VALVE PITS FOR GAS PIPE LINES FOR DUSUP AT DEWA POWER PLANT JEBEL ALI',
    //     location: 'Dubai',
    //     client: 'M/S Dubai supply authority',
    //     index: '14',
    // },
    // {
    //     id: uuidv4(),
    //     img: `https://www.poweron.ae/assets/uploads/project/construction-of-warehouse-cum-office.jpg`,
    //     category: 'CONSTRUCTION OF WAREHOUSE CUM OFFICE',
    //     location: 'Dubai',
    //     client: 'M/S Munawel interior llc',
    //     index: '15',
    // },
    // {
    //     id: uuidv4(),
    //     img: `https://www.poweron.ae/assets/uploads/project/pipe-washing-facility-and-over-head-crane-rail-foundation-for-petro-stem-at-jebel-ali-industrial-ar.jpg`,
    //     category: 'PIPE WASHING FACILITY AND OVER HEAD CRANE RAIL FOUNDATION FOR PETRO STEM AT JEBEL ALI INDUSTRIAL AREA 1',
    //     location: 'Dubai',
    //     client: 'M/S Petrosterm Middle East',
    //     index: '16',
    // },
    // {
    //     id: uuidv4(),
    //     img: portfolio4,
    //     category: 'Construction',
    //     location: 'Twelve, Portland',
    //     client: 'Gerding Development',
    //     index: '04',
    // },

]

export default portfolioTwoData;