

const MechanicalTools = [
  { slNo: 29, equipmentName: "TIG/MIG welding Machine with HF Start (GTAW)", number: 2 },
  { slNo: 30, equipmentName: "Power Paint Mixer", number: 1 },
  { slNo: 31, equipmentName: "RIDGID Pipe Threading Machine", number: 2 },
  { slNo: 32, equipmentName: "Pipe Cutting Machine", number: 2 },
  { slNo: 33, equipmentName: "Diesel Generator 30 KVA", number: 3 },
  { slNo: 34, equipmentName: "Diesel Generator 10 KVA", number: 4 },
  { slNo: 35, equipmentName: "14\" Chop Saw", number: 2 },
  { slNo: 36, equipmentName: "Bench Drill", number: 1 },
  { slNo: 37, equipmentName: "Pallet Trolley - 5 ton", number: 2 },
  { slNo: 38, equipmentName: "Torque Wrench", number: 2 }

  ];
  

export default MechanicalTools;