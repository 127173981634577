import React from 'react'
import FooterTwo from '../Components/Footer/FooterTwo'
import HeaderOne from '../Components/Header/HeaderOne'
import bannerBg from '../assets/img/page-banner.jpg';
import PageBanner from '../Components/PageBanner';
import GalleryTwo from '../Components/Team/GalleryTwo';
import GalleryOne from '../Components/Team/GalleryOne';

const GalleryPage = () => {
  return (
    <>
        <HeaderOne/>
        <PageBanner title='Our Projects' bannerBg={bannerBg} currentPage='Projects' />
        <GalleryTwo/>
        <GalleryOne/>
        <FooterTwo/>
    </>
  )
}

export default GalleryPage