import {v4 as uuidv4} from 'uuid';
import portfolio1 from '../../assets/img/project/1.jpg';
import portfolio2 from '../../assets/img/project/2.jpg';
import portfolio3 from '../../assets/img/project/3.jpg';
import portfolio4 from '../../assets/img/project/4.jpg';

const portfolioOneData = [ 
    {
        id: uuidv4(),
        img: `/img/noimage.png`,
        name:'UPGRADING OF EMDAD PUMPING FACILITY AND ASSOCIATED WORKS AT JEBEL ALI',
        contract_value: '2,550,000',
        client: 'EMDAD',
        main_contractor:'Fortune Energy and Engineering Solutions',
               // date: 'Dubai',
        index: '06',
        scope:'Extension of equipment room - pipe supports - electric cable trench from the substation to filter pump- microcin filter foundation-chipping and grouting of existing pump foundation filter water separator shed - duct bank.'
    },
    {
        id: uuidv4(),
        img: '/img/noimage.png',
        contract_value: '2.4 million',
        client: 'Oil Well Middle East',
        // date: 'Fujairah',
        scope:'Designing, Obtaining Construction Approval from Free-Zone Authority, Construction of the Admin Building, Fire water pump house and fire water tank, as per the Design drawings, including Supply and Installation of Electrical and HVAC Equipment, Fire-Fighting and sewage system, water supply system and allied works. '
    },

    {
        id: uuidv4(), 
        img: '/img/noimage.png',
        contract_value: '390,000',
        client: 'EMARAT',
        // date: 'Dubai',
        scope:'Dismantling of Existing Carousel Filling Machine, Evacuation Unit, Pneumatic Control Panel and Existing Cabling. Installation of New 12 Scale LPG Carousel Filling Machine, Evacuation Unit, Pneumatic Control Panel, Admission & Ejection Unit including laying and modifications to power and process lines.3" Class 300 LPG Piping Modification works in Truck Loading Gantries 1 and 2. Roto Gauge Installation and Tank Calibration of 150MT Horizontal Bullet Propane Tank (Tank -5).'
    },

    {
        id: uuidv4(),
        img: '/img/noimage.png',
        contract_value: '101,255',
        client: 'MELUBCO',
        main_contractor:'B.K Fabrication and Services F.Z.E',
      //  date: 'Dubai',
        scope:'Concrete demolition inside tank MBT2, Concrete demolition and reinstating of tank berm, Removal of existing layers of soil, and PVC pipe inside tank and disposal, Supply and laying new uPVC leak detection pipe up-to tank boundary. Supply and laying layers of dune, road base and bitumen sand.'
    },
    {
        id: uuidv4(),
        img: '/img/noimage.png',
        contract_value: '988,000',
        client: 'Magnum Technology center',
     //   date: 'Dubai',
        index: '02',
        scope:'Grading, leveling, laying interlock over road base, laying kerb-stone and fencing works for an area 15,000 m2.'
    },
    {
        id: uuidv4(),
        img: '/img/noimage.png',
        name:'YARD DEVELOPMENT ACTIVITIES AT HAMRIYAH FREEZONE',
        contract_value: '1.04 Million',
        client: 'Magnum Technology Center',
     //   date: 'Dubai',
        index: '03',
        scope:'Vegetation Removal, Road Base Levelling, Supply and Paving of Interlock in 15,000m2 Area, Kerbstone paving for 450 RM, Fabrication and Installation of Chain Link Fencing and Access Gate for 450 RM.'
    },
    {
        id: uuidv4(),
        img: `/img/noimage.png`,
        name:'CONSTRUCTION OF WAREHOUSE AT AL QOUZ',
        contract_value: '2,600,000',
         client: 'TAMAM BUILDING EXHIBITION LLC',
      //  date: 'Dubai',
        index: '07',
        scope:'Construction of warehouse including bloc walls, partitions, structural steelwork, and electrical cabling works'
    },
    // {
    //     id: uuidv4(),
    //     img: `https://www.poweron.ae/assets/uploads/project/construction-of-civil-foundation-at-dusup-plant-margham.jpg`,
    //     name:'UPGRADING OF EMDAD PUMPING FACILITY AND ASSOCIATED WORKS AT JEBEL ALI',
    //     contract_value: '2,550,000',
    //      client: 'EMDAD',
    //      main_contractor:'Fortune Energy and Engineering Solutions',
    //   //  date: 'Dubai',
    //     index: '08',
    //     scope:'Extension of equipment room - pipe supports - electric cable trench from the substation to filter pump- microcin filter foundation-chipping and grouting of existing pump foundation filter water separator shed - duct bank.'
    // },
    // {
    //     id: uuidv4(),
    //     img: `https://www.poweron.ae/assets/uploads/project/refurbishment-of-drainage-and-sanitary-system.jpg`,
    //     name:'CONSTRUCTION OF WAREHOUSE AT AL QOUZ',
    //     contract_value: '2,600,000',
    //     client: 'TAMAM BUILDING EXHIBITION LLC',
    //   //  date: 'Dubai',
    //     index: '09',
    //     scope:'Construction of warehouse including bloc walls, partitions, structural steelwork, and electrical cabling works'
    // },
    {
        id: uuidv4(),
        img: `/img/noimage.png`,
        name:'CIVIL AND ELECTRICAL MAINTENANCE WORK IN PETROSTEM FACTORY',
        contract_value: '456,000',
        client: 'PETROSTEM',
       // date: 'Dubai',
        index: '10',
        scope:''
    },
    // {
    //     id: uuidv4(),
    //     img: `https://www.poweron.ae/assets/uploads/project/dubal-corner-risk-improvement-project-for-dusup.jpg`,
    //     name:'CIVIL AND ELECTRICAL MAINTENANCE WORK IN PETROSTEM FACTORY',
    //     contract_value: '456,000',
    //      client: 'PETROSTEM',
    //    // date: 'Dubai',
    //     index: '11',
    //     scope:''
    // },
    {
        id: uuidv4(),
        img: `/img/noimage.png`,
       // name:'',
        contract_value: '507,500',
        main_contractor:'AUDEX FUJAIRAH LL FZE',
       // client: 'M/S Dubai Supply Authority',
       // date: 'Dubai',
        index: '12',
        scope:'Pipe sleepers, Foam Skid shelter, IBC Container foundation and shelter, equipment foundations, and Cross over Platform.'
    },
    
    {
        id: uuidv4(),
        img: `/img/noimage.png`,
       // name:'',
        contract_value: '2.46 million',
        main_contractor:'   LAMPRELL ENERGY LIMITED',
        // client: 'PETROSTEM',
       // date: 'Dubai',
        index: '13',
        scope:'Civil Works includes RCC pipe supports and RCC foundation, Impact wall, Pig Launcher/ receiver pads, RCC Containment pits, RCC boundary wall 200 RM, Epoxy protective coating to RCC wall, access road 6m wide, 200RM, light poles foundations and solar light panels, Earthing grid system, Chain link fence, etc.'
    },
    {
        id: uuidv4(),
        img: `/img/noimage.png`,
        contract_value: '245,487',
        main_contractor:'PACT Engineering',
        client: 'EMICOOL LLC.',
       // date: 'Dubai',
        index: '14',
        scope:'Panel water tanks and piping works for water treatment unit, Construction of foundations for tanks and container skid.'
    },
    {
        id: uuidv4(),
        img: `/img/noimage.png`,
        name:'PROJECT: DEVELOPMENT OF YARD AREA AT HAMRIYAH FREE ZONE 2',
        contract_value: '507,000',
        client: 'OIL WELL MIDDLE EAST',
       // date: 'Dubai',
        index: '15',
        scope:'Development of open fabrication yard which includes leveling and graveling in yard area, boundary fence, and interlock paving, light pole foundations and cabling works, etc. at Hamriyah free zone-2.'
    },
    {
        id: uuidv4(),
        img: `/img/noimage.png`,
        name:'ACCESS ROAD DECORATIVE WORKS AT SHERATON HOTEL MALL OF THE EMIRATES',
        contract_value: '650,000',
        main_contractor:'Enova (Subsidiary of Majid Al Futtaim)',
         client: 'SHERATON HOTEL',
       // date: 'Dubai',
        index: '16',
        scope:'Stone Paving, Interlock Laying, RCC Slab, Granite laying, and landscaping, etc.'
    },
    {
        id: uuidv4(),
        img: `/img/noimage.png`,
        name:'CONSTRUCTION OF TISSUE FACTORY SHED EXTENSION',
        contract_value: '2.1 million',
        client: 'MASAFI WATER CO. MASAFI, FUJAIRAH',
       // date: 'Dubai',
        index: '17',
        scope:'Civil foundation works & extension of structural works for the shed 100mx10m, including  foundation, flooring, supply & erection of roof structure, sandwich panel roofing, covered car parking, etc., and various maintenance jobs.'
    },
    {
        id: uuidv4(),
        img: `/img/noimage.png`,
        name:'NEW WAREHOUSE FOR ERHARDT AND PARTNER AT DUBAI WORLD CENTRAL',
        contract_value: '1.1 Million',
        client: 'ERHARDT AND PARTNER, , DUBAI WORLD CENTRE',
       // date: 'Dubai',
        index: '18',
        scope:'Interior works including Partition and ceiling, covered car park, sanitary and plumbing works, floor tiling works, etc.'
    },
    {
        id: uuidv4(),
        img: `/img/noimage.png`,
        name:'INTERIOR WORKS OF OFFICE AND COVERED CAR PARKS AT JAFZA SOUTH',
        contract_value: '703,000',
         client: 'SIMBA TOYS MIDDLE EAST',
       // date: 'Dubai',
        index: '19',
        scope:'Gypsum partition & ceiling works, covered car park 21 parking, pump room, wooden floor & canopy, floor tiles, and floor carpets, interlock yard paving (20,000m2), paneled water tank and plumbing works, Pumproom wiring and DB connections.'
    },
    {
        id: uuidv4(),
        img: `/img/noimage.png`,
        name:'GYPSUM PARTITION WORK AT ICD BROOKFIELD PLACE',
        contract_value: '451,000',
        client: 'MULTIPLEX SSANGYONG',
       // date: 'Dubai',
        index: '20',
        scope:''
    },
    {
        id: uuidv4(),
        img: `/img/noimage.png`,
        name:'RENOVATION OF LABOUR CAMP',
        contract_value: '1.4 Million',
        client: 'RANI INTERNATIONAL DUBAI',
       // date: 'Dubai',
        index: '21',
        scope:'Renovation of labor camp- maintenance for staff accommodations- Blockwork, plastering and painting, floor, tiles, water supply, and sanitary works, etc.'
    },
    {
        id: uuidv4(),
        img: `/img/noimage.png`,
        name:'FIRE PUMP ROOM MODIFICATION',
        contract_value: '820,000',
         client: 'MALL OF THE EMIRATES, DUBAI',
       // date: 'Dubai',
        index: '22',
        scope:'Construction of Fire Pump room, access ramp, pump foundations, epoxy floor coating, etc.'
    },
    {
        id: uuidv4(),
        img: `/img/noimage.png`,
        name:'REFURBISHMENT OF DRAINAGE AND SANITARY SYSTEM, EMIRATES ATRIUM BUILDING.',
        contract_value: '1.25 Million',
        client: 'EMIRATES PETROLEUM LTD, DUBAI',
       // date: 'Dubai',
        index: '23',
        scope:'Refurbishment of drainage and replacement of the sanitary system of Emirate Atrium Building'
    },
    {
        id: uuidv4(),
        img: `/img/noimage.png`,
        main_contractor:'M/S SUEZ, ABU DHABI',
        contract_value: '800,000',
       // client: 'M/S Dubai Supply Authority',
       // date: 'Dubai',
        index: '24',
        scope:'Equipment Foundations, concrete protection works, RCC paving & Kerbstone laying,etc., at Margham gas plant.'
    },
    // {
    //     id: uuidv4(),
    //     img: portfolio4,
    //     category: 'Decoration',
    //     client: 'Home Decoration',
    //     date: 'Nov 28 2018'
    // },
]

export default portfolioOneData;