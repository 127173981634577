import React from 'react'
import FooterTwo from '../Components/Footer/FooterTwo'
import HeaderOne from '../Components/Header/HeaderOne'
import bannerBg from '../assets/img/page-banner.jpg';
import PageBanner from '../Components/PageBanner';
import BrandCarouselThree from "../Components/Brands/BrandCarouselThree";
import ContactPageContents from "../Components/Contact/ContactPageContents";
import CareerPageContents from '../Components/Careers/Careers';

const CareersPage = () => {
  return (
    <>
        <HeaderOne/>
        <PageBanner title='Careers' bannerBg={bannerBg} currentPage='careers' />
        <CareerPageContents/>
        <BrandCarouselThree/>
        <FooterTwo/>
    </>
  )
}

export default CareersPage;
