import React, { useState } from "react";
import FooterTwo from "../Components/Footer/FooterTwo";
import HeaderOne from "../Components/Header/HeaderOne";
import bannerBg from "../assets/img/page-banner.jpg";
import PageBanner from "../Components/PageBanner";
import Details from "../Components/Construction-Equipment/Details";

const ConstructionEquipment = () => {
  const [pathName, setPathName] = useState("constructionEquipment");

  return (
    <>
      <HeaderOne />
      <PageBanner
        title={pathName=="constructionEquipment" ? "CONSTRUCTION EQUIPMENT & TOOLS" : "YARD FACILITY"}
        bannerBg={bannerBg}
        currentPage={pathName=="constructionEquipment" ? "CONSTRUCTION EQUIPMENT & TOOLS" : "YARD FACILITY"}
      />
      <Details pathName={pathName} setPathName={setPathName} />
      <FooterTwo />
    </>
  );
};

export default ConstructionEquipment;
