import React from "react";
import FooterTwo from "../Components/Footer/FooterTwo";
import HeaderOne from "../Components/Header/HeaderOne";
import PageBanner from "../Components/PageBanner/index";
import bannerBg from "../assets/img/page-banner.jpg";
import Details from "../Components/health-safety/Details";


const HealthSafety = () => {
  return (
    <>
      <HeaderOne />
      <PageBanner
        title="HEALTH SAFETY AND
ENVIRONMENT POLICY"
        bannerBg={bannerBg}
        currentPage="HEALTH SAFETY AND
ENVIRONMENT POLICY"
      />
      <Details />
      <FooterTwo />
    </>
  );
};

export default HealthSafety;
