import { v4 as uuidv4 } from 'uuid';
import brand1 from '../../assets/img/brand-logo/b1.png';
import brand2 from '../../assets/img/brand-logo/b2.png';
import brand3 from '../../assets/img/brand-logo/b3.png';
import brand4 from '../../assets/img/brand-logo/b4.png';
import brand5 from '../../assets/img/brand-logo/b5.png';


const brandCarouselOneData = [
    {
        id: uuidv4(),
        brand: '/img/brand/canadian2.png',
        name: 'Google'
    },

    {
        id: uuidv4(),
        brand: '/img/brand/enoc1.png',
        name: 'Google'
    },

    {
        id: uuidv4(),
        brand: '/img/brand/majid1.png',
        name: 'Google'
    },

    {
        id: uuidv4(),
        brand: '/img/brand/masafi1.png',
        name: 'Google'
    },

    {
        id: uuidv4(),
        brand: '/img/brand/munawel1.png',
        name: 'Google'
    },

    {
        id: uuidv4(),
        brand: '/img/brand/vopak1.png',
        name: 'Google'
    },
    {
        id: uuidv4(),
        brand: '/img/brand/melubco1.png',
        name: 'Google'
    },


]

export default brandCarouselOneData;