import React from "react";

const Details = () => {
  return (
    <section className="blog-wrapper news-wrapper section-padding quality-policy-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-12">
            <div className="blog-post-details border-wrap">
              <div className="single-blog-post post-details">
                <div className="post-content">
                  {/* <div className="post-cat">
                                    <Link to="#">BUILDING</Link>
                                </div> */}
                  <h2>Quality Policy</h2>
                  {/* <div className="post-meta">
                                    <span><i className="fal fa-comments"></i>35 Comments</span>
                                    <span><i className="fal fa-calendar-alt"></i>24th March 2019</span>
                                </div> */}

                  <p>
                    <strong>DIAMOND HOUSE TECHNICAL SERVICES LLC</strong> strives hard for full
                    customer satisfaction by providing good quality products,
                    which represent good value for money at the most economical
                    cost.
                  </p>
                  <p>
                    <strong>DIAMOND HOUSE TECHNICAL SERVICES LLC</strong> is committed to
                    producing the highest quality products, and related services
                    and anticipate that its products, would certainly become a
                    worldclass and the first choice for the consumer by
                    monitoring and improving quality management system process,
                    products and services, and train, develop, and motivate
                    personnel
                  </p>
                  <p>It’s the management objective to achieve the following:</p>

                  <ul>
                    <li>
                      To provide economical, quality-oriented and technically
                      sound engineering solutions and thereby achieve total
                      customer satisfaction and business sustainability.
                    </li>
                    <li>Make profit through continual improvement.</li>
                    <li>
                      Faster execution through the refined process and
                      templates.
                    </li>
                    <li>
                      Fewer project problems encountered making use of proactive
                      project Management process.
                    </li>
                    <li>Build a higher quality product for the first time.</li>
                    <li>
                      Maintain a high level of Professionalism, Honesty, and
                      Integrity.
                    </li>
                    <li>
                      Better organizational decision making through secured
                      project information and effective communication.
                    </li>
                    <li>Highest client satisfaction and less rework.</li>
                  </ul>
                  <p>
                    <strong>DIAMOND HOUSE TECHNICAL SERVICES</strong> LLC Management is committed
                    to reviewing the Quality policy and objectives,
                    periodically, to ensure its continuing suitability. We are
                    committed to comply with customer and regulatory
                    requirements and continually improve the effectiveness of
                    the quality management system.
                  </p>
                  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Details;
