import React, { useState, useEffect } from "react";
import { Checkbox, Radio } from "antd";
import { API_URL } from "../../constants";

const checkboxDataOne = [
  "Contracting & Maintenance of Residential & Industrial buildings",
  "Flooring & Tiling works",
  "Plastering, Painting & Carpentry works",
  "Plumbing & Sanitary works",
  "Interlock Pavements",
  "Dry wall partition and False Ceiling works",
  "Interior decoration works",
  "Fire Fighting works",
];

const checkBoxDataTwo = [
  "Metal Cladding Works",
  "Roof water proofing and concrete protection works",
  "Industrial Floor Coatings",
  "Decorative painting systems",
  "Reclamation works",
  "Fencing works",
  "Electrical repairing works",
  "General Maintenance",
];

const QuotePageContents = () => {
  const CheckboxGroup = Checkbox.Group;
  const [checkedList, setCheckedList] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    contactOption: "",
    aboutOrganization: "",
    overviewOrganization: "",
    projectDetails: "",
    organizationTypes: [],
    file: null,
  });
  console.log('selected option is', selectedOption)
  const [showMessage, setShowMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [message, setMessage] = useState('');
  const [isSuccess, setIsSuccess] = useState(true);
  const [showRadioError, setShowRadioError] = useState(false); // State to manage radio button validation
  const [isLoading, setIsLoading] = useState(false);
  const [showPhoneNumberError, setShowPhoneNumberError] = useState(false); // State to manage phone number validation
  const SuccessMessage = ({ message, onClose, isSuccess, showTimer }) => {
    console.log('show timer is', showTimer)
    useEffect(() => {
      // if (showTimer) {
        const timer = setTimeout(() => {
          onClose();
        }, 3000);

        return () => clearTimeout(timer);
      // }
    }, [onClose, showTimer]);

    return (
      <div className={isSuccess ? 'success-message' : 'error-message'}>
        <span>{message}</span>
         {/* <button onClick={onClose}>X</button>  */}
      </div>
    );
  };

  const onChange = (checkedValue) => {
    const updatedCheckedList = checkedList.includes(checkedValue)
      ? checkedList.filter((item) => item !== checkedValue)
      : [...checkedList, checkedValue];
    
    setCheckedList(updatedCheckedList);
    setFormData({
      ...formData,
      organizationTypes: updatedCheckedList,
    });

    if (updatedCheckedList.length > 0) {
      setShowMessage(false); // Hide message when at least one checkbox is selected
    }
  };

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
    setFormData({
      ...formData,
      contactOption: e.target.value,
    });
    setShowRadioError(false); // Reset radio button error when user makes a selection
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: files ? files[0] : value,
    });
    // Phone number validation
    if (name === "phoneNumber") {
      if (value.length !== 10 || isNaN(value) || value[0]==='0') {
        setShowPhoneNumberError(true);
      } else {
        setShowPhoneNumberError(false);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // Check if a radio button is selected
    if (!selectedOption) {
      setShowRadioError(true);
      setIsLoading(false)
      if (checkedList.length === 0) {
        console.log('entered here')
        setShowMessage(true); // Show message only if no checkboxes are selected
        setIsLoading(false);
        return;
      }
      return;
    }

    console.log('checked list is', checkedList)

    // Check if organization types are selected
    
// Check if phone number is valid
// if (showPhoneNumberError || formData.phoneNumber.length !== 10) {
//   setShowPhoneNumberError(true);
//   setIsLoading(false);
//   return;
// }
    // Proceed with form submission if validation passes
    const apiEndpoint = `${API_URL}api/v1/quote/create`;

    const body = new FormData();
    body.append("name", formData.name);
    body.append("email", formData.email);
    body.append("phoneNumber", formData.phoneNumber);
    body.append("contactOption", formData.contactOption);
    body.append("aboutOrganization", formData.aboutOrganization);
    body.append("overviewOrganization", formData.overviewOrganization);
    body.append("projectDetails", formData.projectDetails);
    formData.organizationTypes.forEach(type => body.append("organizationTypes", type));
    if (formData.file) {
      body.append("file", formData.file);
    }

    try {
      const response = await fetch(apiEndpoint, {
        method: "POST",
        body: body,
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (data.success) {
        setMessage('Quote request submitted successfully');
        setIsSuccess(true);
        setFormData({
          name: "",
          email: "",
          phoneNumber: "",
          contactOption: "",
          aboutOrganization: "",
          overviewOrganization: "",
          projectDetails: "",
          organizationTypes: [],
          file: null,
        })
        setSelectedOption("");
        setCheckedList([]);
        setShowMessage(false)
      }
      setIsLoading(false);
      setShowSuccessMessage(true);
      console.log("Success:", data);
    } catch (error) {
      setMessage('Invalid quote details');
      setIsLoading(false);
      setIsSuccess(false);
      setShowMessage(true);
      console.error("Error:", error);
    }
  };

  console.log('show message value', showMessage)

  return (
    <>

      {showSuccessMessage && (
        <SuccessMessage
          message={message}
          onClose={() => setShowSuccessMessage(false)}
          isSuccess={isSuccess}
          showTimer={checkedList.length > 0} 
        />
      )}
      <div className="contact-us-wrapper section-padding">
        <div className="container">
          <div className="row eq-height">
            <div className="col-lg-12 col-12">
              <div className="contact-form">
                <h6 className="text-center" style={{ marginBottom: "30px" }}>
                  Looking for an estimate for your project? Fill out the details and we will get back to you.
                </h6>

                <form onSubmit={handleSubmit} method="POST" className="row" id="contact-form">
                  <div className="col-md-6 col-12">
                    <div className="single-personal-info">
                      <input type="text" name="name" value={formData.name} placeholder="First Name" onChange={handleChange} required />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="single-personal-info">
                      <input type="email" name="email" value={formData.email} placeholder="Email" onChange={handleChange} required />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="single-personal-info">
                      <input type="text" name="phoneNumber" value={formData.phoneNumber} placeholder="Number" onChange={handleChange} required />
                      {/* {showPhoneNumberError && <p className="quotePageRadioOption">Please enter a valid 10 digits number</p>} */}
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <Radio.Group onChange={handleRadioChange} value={selectedOption}>
                      <Radio value="Call me with any questions">Call me with any questions</Radio>
                      <Radio value="Email only about the quote requested">Email only about the quote requested</Radio>
                    </Radio.Group>
                    {showRadioError && <p className="quotePageRadioOption">Please select a contact option.</p>}
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="single-personal-info">
                      <textarea
                        rows={2}
                        type="text"
                        name="aboutOrganization"
                        value={formData.aboutOrganization}
                        placeholder="About Your Organization"
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="single-personal-info">
                      <textarea
                        rows={2}
                        type="text"
                        name="overviewOrganization"
                        value={formData.overviewOrganization}
                        placeholder="Organization Overview"
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-12 col-12">
                    <div className="single-personal-info">
                      <textarea
                        name="projectDetails"
                        value={formData.projectDetails}
                        placeholder="Project Details"
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="single-personal-info">
                      {checkboxDataOne.map((item) => (
                        <div key={item}>
                          <Checkbox
                            className="quote-checkbox"
                            checked={checkedList.includes(item)}
                            onChange={() => onChange(item)}
                          >
                            {item}
                          </Checkbox>
                        </div>
                      ))}
                      {/* Show message only below the first set of checkboxes */}
                      {showMessage && checkedList.length === 0 && <p className="quotePageRadioOption">Please select organization types.</p>}
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="single-personal-info">
                      {checkBoxDataTwo.map((item) => (
                        <div key={item}>
                          <Checkbox
                            className="quote-checkbox"
                            checked={checkedList.includes(item)}
                            onChange={() => onChange(item)}
                          >
                            {item}
                          </Checkbox>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="single-personal-info">
                      <p>Attach Your File</p>
                      <input type="file" name="file" onChange={handleChange} required placeholder="Attach your File" />
                    </div>
                  </div>
                  <div className="col-md-12 col-12">
                    {/* <input
                      className="submit-btn"
                      type="submit"
                      defaultValue="Submit Now"
                    /> */}
                    <button
                      className="submit-btn"
                      type="submit"
                      disabled={isLoading}
                    >
                      {isLoading ? (
                        <img
                          src="/img/loading-gif.gif"
                          alt="Loading..."
                          className="loading-gif"
                        />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>
                <span className="form-message" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QuotePageContents;

