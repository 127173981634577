import { Link } from "react-router-dom";
import teamOneData from "./teamOneData";

const GalleryOne = () => {
    return (
        <>
          <section className="our-team-wrapper section-padding pt-0">
                <div className="container">
                    <div className="row">
                        

                    </div>
                </div>
            </section>
        </>
    )
}

export default GalleryOne;