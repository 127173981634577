import { useState } from "react";
import { Link } from "react-router-dom";
import mainLogo from "../../assets/img/logo.png";
import MobileMenu from "./MobileMenu";
import OffsetMenu from "./OffsetMenu";

const HeaderOne = () => {
  const [search, setSearch] = useState(true);
  const [offset, setOffset] = useState(true);
  const [mobileMenu, setMobileMenu] = useState(true);

  const handleSearch = () => {
    setSearch(!search);
  };

  const handleOffset = (e) => {
    e.preventDefault();
    setOffset(!offset);
  };

  const handleMobileMenu = () => {
    setMobileMenu(!mobileMenu);
  };
  return (
    <>
      <OffsetMenu offset={offset} handleOffset={handleOffset} />
      <header className="header-wrap header-1">
        <div className="container-fluid d-flex justify-content-between align-items-center">
          <div className="logo">
            <Link to="/">
              <img
                className="logo-icon"
                src="/img/dhtech-icon.svg"
                alt="logo"
              />
            </Link>
          </div>
          <div className="header-right-area d-flex">
            <div className="main-menu d-none d-xl-block">
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/about">about</Link>
                </li>
                <li>
                  <Link to="/projects">Projects</Link>
                </li>
                <li>
                  <Link to="/gallery">Gallery</Link>
                </li>
                <li>
                  <Link to="/constructionEquipment">Our Tools</Link>
                </li>
                {/* <li><a href="#">Pages <i className="fal fa-plus"></i></a>
                            <ul className="sub-menu">
                                <li><Link to="/Team">team</Link></li>
                                <li><Link to="/faq">faq</Link></li>
                                <li><Link to="/projects">projects</Link></li>
                                <li><Link to="/pricing">Pricing</Link></li>
                            </ul>
                        </li> */}
                <li>
                  <Link to="/careers">Careers</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
                {/* <li>
                            <a href="#" onClick={handleSearch} className="search-btn"><i className="fas fa-search"></i></a>
                            <div className={search ? 'search-box' : 'search-box show'}>
                                <form action="#">
                                    <input type="text" placeholder="Search"/>
                                    <button type="submit"><i className="fal fa-search"></i></button>
                                </form>
                            </div>
                        </li> */}
              </ul>
            </div>
            <div className="header-right-elements d-flex align-items-center justify-content-between">
              <Link to="/quote" className="theme-btn d-none d-sm-block">
                Get Free Quote
              </Link>
              <span
                onClick={handleOffset}
                className="side-menu-toggle d-none d-xl-block"
              >
                <i className="fal fa-bars"></i>
              </span>
              <div className="d-inline-block ms-4 d-xl-none">
                <div className="mobile-nav-wrap">
                  <div id="hamburger" onClick={handleMobileMenu}>
                    <i className="fal fa-bars"></i>
                  </div>
                  <MobileMenu
                    mobileMenu={mobileMenu}
                    handleMobileMenu={handleMobileMenu}
                  />
                </div>
                <div className="overlay"></div>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default HeaderOne;
